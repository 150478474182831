import './App.css';
import React, { useEffect } from 'react';

function App() {

  const getQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    let queryParams = {};
    for (let param of params) {
      queryParams[param[0]] = param[1];
    }
    return queryParams;
  }
  const queryParams = getQueryParams();

  useEffect(() => {
    const _url = `${queryParams.calendar_url}?hide_event_type_details=1`;
    function isCalendlyEvent(e) {
      return e.data.event && e.data.event.indexOf('calendly') === 0;
    }
    function validateEvent(e) {
      if (isCalendlyEvent(e)) {
        if (e.data.event === 'calendly.event_scheduled') {
          const uri = e.data?.payload?.event?.uri;
          const splitUri = uri?.split('/').reverse() ?? [];
          sendCalendlyInfo(splitUri ? splitUri[0] : null);
        }
      }
    }
    async function sendCalendlyInfo(session_id) {
      console.log('sending...');
      if (session_id !== null) {
        const payload = {
          professional_id: queryParams.professional_id,
          user_id: queryParams.user_id,
          session_id: session_id,
        };
        try {
          const response = await fetch('https://stg.api.momentu.co/activity/chabot-individual-session', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const data = await response.json();
          return data;
        } catch (error) {
          console.error('the session has not been created');
        }
      }
    }
    window.addEventListener('message', validateEvent);
    window?.Calendly.initInlineWidget({
      url: _url,
      parentElement: document.getElementById('calendly-embed-container'),
      prefill: {
        name: queryParams?.user_name ?? '',
        email: queryParams?.user_email ?? '',
        customAnswers: {}
      }
    });
    return () => {
      window.removeEventListener('message', validateEvent);
    };
  }, [queryParams.calendar_url, queryParams.professional_id, queryParams?.user_email, queryParams.user_id, queryParams?.user_name]);

  return (
    <div className="App">
      <main className="App-content">
        <div className="calendly-embed-container" />
      </main>
    </div>
  );
}

export default App;